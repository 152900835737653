import React from 'react';
import './Promo.css';

function Promo() {
    return (
        <section className="promo">
            <div className="promo__background"></div>
            <h1 className="promo__title">Учебный проект студента факультета Веб-разработки.</h1>          
        </section>
    );
}

export { Promo };